var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container profile" },
    [
      _c(
        "div",
        { staticClass: "payment-header" },
        [
          _vm.isSuccessfulPayment
            ? _c("div", { staticStyle: { height: "90px" } })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "image" },
            [
              _c("icon", {
                attrs: {
                  src: "img/payment/payment_check.gif",
                  width: "8.8rem",
                  height: "8.8rem",
                  cover: false
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("결제가 완료되었어요!")]),
          _vm.isSuccessfulPayment && _vm.isCustom
            ? [
                _c("div", { staticClass: "text" }, [
                  _vm._v(" 조금만 기다리시면"),
                  _c("br"),
                  _vm._v(" 전담 매니저가 진행 도움을 위해 "),
                  _c("span", { staticClass: "color-lavender" }, [
                    _vm._v("연락을 드려요.")
                  ]),
                  _c("br"),
                  _vm._v(" (평일, 최대 12시간 내)"),
                  _c("br"),
                  _c("br"),
                  _c(
                    "a",
                    {
                      staticClass: "text-underline ui-btn",
                      on: {
                        click: function($event) {
                          return _vm.$gotoWeb(
                            "http://pf.kakao.com/_yzxhwC/chat"
                          )
                        }
                      }
                    },
                    [_vm._v("빠른 도움이 필요하신가요?")]
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "profile-info receipt-ui" },
        [
          _vm.$route.params.artistidx > 0
            ? _c("div", [
                _c("h4", [_vm._v("예약 정보")]),
                _c("div", { staticClass: "info-block" }, [
                  _c("div", { staticClass: "d-flex w-100" }, [
                    _c("span", {
                      staticClass: "artist-image ui-bg-img",
                      style: {
                        backgroundImage:
                          "url(" +
                          _vm.$lib.cdnUrl(_vm.artistData.teamImgUrl) +
                          ")",
                        backgroundColor: "#eee"
                      }
                    }),
                    _c("div", { staticClass: "product-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "product-name ui-ellipsis",
                          staticStyle: { flex: "1", "line-height": "2.8rem" }
                        },
                        [_vm._v(_vm._s(_vm.artistData.teamName))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "color-lavender",
                          style: {
                            fontSize: "2rem",
                            fontWeight: 600,
                            lineHeight: "2.8rem"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$lib.addComma(
                                _vm.isPartCancel
                                  ? _vm.cancellableAmt
                                  : _vm.finalPaymentPrice
                                  ? _vm.finalPaymentPrice
                                  : _vm.payAmt
                              )
                            ) + "원 (부가세 포함)"
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c("div", {
            staticClass: "divider-dashed",
            staticStyle: { margin: "2.4rem 0" }
          }),
          _vm.offerData.nowState === "wait" && _vm.offerData.payType === "VBANK"
            ? _c("h4", [_vm._v("입금 정보")])
            : _c("h4", [_vm._v("결제 정보")]),
          _vm.offerData.nowState === "wait" && _vm.offerData.payType === "VBANK"
            ? [
                _c("div", { staticClass: "info-block" }, [
                  _c("ul", [
                    _c("li", [_vm._v("계좌번호")]),
                    _c("li", { staticClass: "float-right" }, [
                      _vm._v(
                        _vm._s(_vm.offerData.payFnNm || "-") +
                          " | " +
                          _vm._s(_vm.pVacctNo || "-")
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "info-block" }, [
                  _c("ul", [
                    _c("li", [_vm._v("입금기한")]),
                    _c("li", { staticClass: "float-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm
                            .moment(Number(_vm.pExpDt) * 1000)
                            .format("YYYY-MM-DD HH:mm:ss")
                        )
                      )
                    ])
                  ])
                ])
              ]
            : [
                _c("div", { staticClass: "info-block" }, [
                  _c("ul", [
                    _c("li", [_vm._v("결제 일시")]),
                    _c("li", { staticClass: "float-right" }, [
                      _vm._v(
                        _vm._s(
                          !_vm.isCustom
                            ? _vm.payAuthDt(_vm.offerData.payAuthDt)
                            : _vm.moment().format("YYYY-MM-DD HH:mm:ss")
                        )
                      )
                    ])
                  ])
                ]),
                _vm.isClient && !_vm.isCustom
                  ? _c("div", { staticClass: "info-block" }, [
                      _c("ul", [
                        _c("li", [_vm._v("결제 수단")]),
                        _c("li", { staticClass: "float-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.offerData.payType === "CARD"
                                ? "신용카드"
                                : "가상계좌"
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ],
          _vm.isClient
            ? [
                !_vm.isCustom
                  ? _c("div", { staticClass: "info-block" }, [
                      _c("ul", [
                        _c(
                          "li",
                          { staticClass: "color-lavender ui-weight-6" },
                          [_vm._v("최종 결제 금액")]
                        ),
                        _c(
                          "li",
                          {
                            staticClass:
                              "color-lavender ui-weight-6 float-right",
                            style: {
                              fontSize: "2rem",
                              fontWeight: 600
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.isPartCancel
                                  ? _vm.$lib.addComma(_vm.cancellableAmt)
                                  : _vm.$lib.addComma(_vm.payAmt)
                              ) + "원"
                            )
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            : [
                _c("div", { staticClass: "info-block" }, [
                  _c("ul", [
                    _c("li", [_vm._v("결제 금액")]),
                    _c("li", { staticClass: "float-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isPartCancel
                            ? _vm.$lib.addComma(_vm.cancellableAmt)
                            : _vm.$lib.addComma(_vm.payAmt)
                        ) + "원"
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "info-block" }, [
                  _c("ul", [
                    _c("li", [_vm._v("부가세")]),
                    _c("li", { staticClass: "float-right" }, [
                      _vm._v(_vm._s(_vm.$lib.addComma(_vm.artistVat)) + "원")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "info-block" }, [
                  _c("ul", [
                    _c("li", [_vm._v("수수료")]),
                    _c("li", { staticClass: "float-right" }, [
                      _vm._v(_vm._s(_vm.$lib.addComma(_vm.fees)) + "원")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "info-block" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        staticClass: "color-lavender",
                        style: {
                          fontSize: "2rem",
                          fontWeight: 600
                        }
                      },
                      [_vm._v("예상 수익 금액")]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "color-lavender float-right",
                        style: {
                          fontSize: "2rem",
                          fontWeight: 600
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$lib.addComma(_vm.incomePrice)) + "원"
                        )
                      ]
                    )
                  ])
                ])
              ]
        ],
        2
      ),
      !_vm.isCustom
        ? [
            _c(
              "div",
              { staticClass: "profile-teamDesc" },
              [
                _vm.isClient
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "safe-infomation",
                          class: { open: _vm.safeInfomationOpen }
                        },
                        [
                          _c(
                            "h4",
                            {
                              style: {
                                fontSize: "2.1rem",
                                fontWeight: 500,
                                margin: "1rem 0"
                              }
                            },
                            [
                              _vm._v("헤이비글 안전결제 안내 "),
                              _c("icon", {
                                attrs: {
                                  src: "img/common/iconfinder-down.png",
                                  width: "22px",
                                  height: "2.4rem",
                                  cover: false
                                },
                                on: {
                                  click: function() {
                                    return (_vm.safeInfomationOpen = !_vm.safeInfomationOpen)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._m(0),
                          _vm._m(1),
                          _vm._m(2)
                        ]
                      )
                    ]
                  : [
                      _c(
                        "h4",
                        { style: { fontSize: "2.4rem", fontWeight: 500 } },
                        [_vm._v("대금 지급 안내")]
                      ),
                      _vm._m(3),
                      _c("p", { staticClass: "ui-p-indent-2" }, [
                        _vm._v(
                          "2. 예상액이므로 실제 금액과 차이가 있을 수 있어요."
                        )
                      ]),
                      _c("p", { staticClass: "ui-p-indent-2" }, [
                        _vm._v("3. 개인의 경우 원천세 공제 후 지급됩니다.")
                      ]),
                      _c("p", { staticClass: "ui-p-indent-2" }, [
                        _vm._v("4. 사업자는 세금계산서 발행이 필요합니다.")
                      ]),
                      _vm._m(4),
                      _vm._m(5)
                    ]
              ],
              2
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "profile-teamDesc" },
        [
          _vm.isClient
            ? [
                _c("h4", { style: { fontSize: "2.4rem", fontWeight: 500 } }, [
                  _vm._v("환불 및 취소 안내")
                ]),
                _vm.isCustom && _vm.cusFlag === 2
                  ? [_vm._m(6), _vm._m(7)]
                  : _vm.isCustom && _vm.cusFlag === 1
                  ? [_vm._m(8), _vm._m(9)]
                  : [
                      _vm._m(10),
                      _c("br"),
                      _c("p", { staticClass: "ui-p-indent-1" }, [
                        _vm._v("엔터테이너가 취소 시 아래의 규정을 따릅니다."),
                        _c("br"),
                        _c(
                          "span",
                          { staticClass: "ui-p-indent d-inline-block" },
                          [
                            _vm._v(
                              "- 13~7일 전 취소 시 : 결제금액의 50%를 배상"
                            )
                          ]
                        ),
                        _c("br"),
                        _c(
                          "span",
                          { staticClass: "ui-p-indent d-inline-block" },
                          [
                            _vm._v(
                              "- 6일 전~당일 취소 시 : 취소 불가, 300%를 배상"
                            )
                          ]
                        ),
                        _c("br"),
                        _c(
                          "span",
                          {
                            staticClass:
                              "ui-p-indent color-lavender text-underline",
                            on: {
                              click: function($event) {
                                return _vm.$router.push("/etc/refund-policy")
                              }
                            }
                          },
                          [_vm._v("자세히 알아보기")]
                        )
                      ])
                    ]
              ]
            : [
                _c("h4", { style: { fontSize: "2.4rem", fontWeight: 500 } }, [
                  _vm._v("전문가의 취소 및 위약금 규정")
                ]),
                _vm._m(11),
                _c(
                  "p",
                  {
                    staticClass: "ui-p-indent",
                    staticStyle: { color: "#ed3f33" }
                  },
                  [_vm._v("- 13~7일 전 취소 시 : 결제금액의 50% 배상")]
                ),
                _vm._m(12),
                _c("p", { staticClass: "ui-p-indent" }, [
                  _vm._v("- 자연재해, 사고, 입원 등의 불가피한 사유인 경우는"),
                  _c("br"),
                  _vm._v("의뢰인과의 협의를 통해 결정합니다."),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticClass: "color-lavender text-underline",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/etc/refund-policy")
                        }
                      }
                    },
                    [_vm._v("자세히 알아보기")]
                  )
                ])
              ]
        ],
        2
      ),
      _vm.isClient
        ? [
            _vm.isSuccessfulPayment
              ? [
                  _vm.artistData.categoryType != "profile"
                    ? [
                        _c("footer-box", {
                          attrs: {
                            single: true,
                            "submit-text": "닫기",
                            "submit-cb": function() {
                              if (_vm.isCustom) {
                                // 메인으로 이동
                                _vm.$router.push("/home")
                              } else {
                                // 결제 내역으로 이동
                                _vm.$router.push("/client/my-offer?nav=2")
                              }
                            }
                          }
                        })
                      ]
                    : [
                        _c("footer-box", {
                          attrs: {
                            rate37: "",
                            single: false,
                            "btn-text": "닫기",
                            "btn-cb": function() {
                              if (_vm.isCustom) {
                                // 메인으로 이동
                                _vm.$router.push("/home")
                              } else {
                                // 결제 내역으로 이동
                                _vm.$router.push("/client/my-offer?nav=2")
                              }
                            },
                            "submit-text": "전문가와 채팅하기",
                            "submit-cb": function() {
                              return _vm.createMsgRoom()
                            }
                          }
                        })
                      ]
                ]
              : !_vm.isCustom
              ? [
                  (_vm.offerData.payType === "VBANK"
                  ? _vm.offerData.nowState !== "wait"
                  : _vm.offerData.nowState !== "cancelRequest" && _vm.canCancel)
                    ? _c("footer-box", {
                        attrs: {
                          single: true,
                          "submit-text": "결제 취소하기",
                          "submit-cb": function() {
                            return _vm.paymentAction("artistCancel")
                          }
                        }
                      })
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "page-modal",
        { ref: "pageModal", attrs: { "use-navi": false } },
        [
          _c(
            "div",
            {
              staticClass: "page-modal-body",
              style: {
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: 0
              },
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _c(
                "div",
                {
                  style: {
                    margin: "auto"
                  }
                },
                [
                  _c("img", {
                    style: {
                      margin: "0 auto 30px",
                      display: "block"
                    },
                    attrs: {
                      slot: "body",
                      src: _vm.$lib.imgUrl("img/payment/checker.png"),
                      alt: "image",
                      width: "128px",
                      height: "128px"
                    },
                    slot: "body"
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "ui-weight-5",
                      style: {
                        fontSize: "28px",
                        color: "#000",
                        textAlign: "center",
                        marginBottom: "20px"
                      }
                    },
                    [
                      _vm._v(" 예약 완료!"),
                      _c("br"),
                      _vm._v(" 조금만 기다려주세요. ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ui-weight-5",
                      style: {
                        fontSize: "20px",
                        color: "#5a5a5a",
                        textAlign: "center"
                      }
                    },
                    [
                      _vm._v(" 헤이비글의 전담 매니저가 연락을 드리고"),
                      _c("br"),
                      _vm._v(" 진행을 도와드릴거예요!"),
                      _c("br"),
                      _vm._v(" (영업일 기준, 최대 12시간 내)"),
                      _c("br"),
                      _c("br"),
                      _c(
                        "a",
                        {
                          staticClass: "text-underline ui-btn",
                          on: {
                            click: function($event) {
                              return _vm.$gotoWeb(
                                "http://pf.kakao.com/_yzxhwC/chat"
                              )
                            }
                          }
                        },
                        [_vm._v("빠른 도움이 필요하신가요?")]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _vm.offerData &&
          /(결혼식|주례없는 결혼식)/.test(_vm.offerData.eventType)
            ? _c(
                "div",
                {
                  staticClass:
                    "ui-weight-5 d-flex align-items-center justify-content-center ui-btn",
                  style: {
                    fontSize: "22px",
                    color: "#000",
                    height: "85px",
                    position: "absolute",
                    bottom: "100px",
                    left: 0,
                    right: 0,
                    borderTop: "1px solid #ececec",
                    width: "100%"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$gotoWeb(
                        "https://blog.naver.com/wimz0816/222641646144"
                      )
                    }
                  }
                },
                [_vm._v("리뷰 쓰고 최대 50,000원 상품권 받기 >")]
              )
            : _vm._e(),
          _c("FooterBox", {
            attrs: {
              "submit-text": "확인",
              "submit-cb": function() {
                return _vm.$refs.pageModal.hide()
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v("1. 헤이비글은 의뢰자와 엔터테이너간 No-Show 및"),
      _c("br"),
      _vm._v("대금 미지급에 대한 문제가 발생하지 않도록"),
      _c("br"),
      _vm._v("안전거래(에스크로) 방식을 도입하고 있습니다.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v("2. 결제 금액은 헤이비글이 안전하게 보관 후"),
      _c("br"),
      _vm._v("등록된 행사일 3일 후 전문가에게 지급합니다.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v("3. 피해가 발생한 경우 2일 이내에 고객센터로"),
      _c("br"),
      _vm._v("문제 제기 시 대금 지급을 보류합니다.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v(" 1. 용어 설명"),
      _c("br"),
      _vm._v(" - 부가세 : 거래 시 최종 소비자가 부담하는 세금"),
      _c("br"),
      _vm._v(" - 원천세 : 국세청에 납부하는 개인소득에 대한 세금"),
      _c("br"),
      _vm._v(" - 수수료 : 헤이비글 이용 수수료 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v(" 5. 정산일 안내"),
      _c("br"),
      _vm._v(" - 1일~15일 진행건 : 23일에 지급"),
      _c("br"),
      _vm._v(" - 16일~말일까지의 진행건 : 익월 9일에 지급 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v(" 6. 단, 의뢰자가 피해로 인한 문제 제기 시"),
      _c("br"),
      _vm._v(" 양자 간 협의 완료 전까지 대금 지급은 보류됩니다. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v("1. 환불 및 취소는 2주 전까지만 가능하며"),
      _c("br"),
      _vm._v("시점에 따라 위약금이 발생합니다. "),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("행사 30일 전까지 : 100% 환불")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("행사 29일 ~ 14일 전 취소 시 : 50% 환불")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("행사 13일 ~ 당일 : 환불 불가")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v("2. 환불 문의"),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("고객센터 : 02-3144-1806, 카카오톡@헤이비글")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v(
          "상담시간 : 평일 10시~18시 (주말 및 공휴일 제외, 점심 13시~14시 제외)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v("1. 환불 안내"),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("체크인일자 3일전 취소 시 공제없이 100% 환불")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("체크인일자 2일전 취소 시 30%공제 후 환불")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("체크인일자 1일전 취소 시 50%공제 후 환불")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("체크인일자 당일/No-Show 취소 및 환불불가")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("주말 및 공휴일 취소 시 익영업일에 취소한 것으로 인정")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-2" }, [
      _vm._v("2. 예약 및 취소 문의"),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v("고객센터 : 02-3144-1806, 카카오톡@헤이비글")
      ]),
      _c("br"),
      _c("span", { staticClass: "ui-p-indent d-inline-block" }, [
        _vm._v(
          "상담시간 : 평일 10시~18시 (주말 및 공휴일 제외, 점심 13시~14시 제외)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent-1" }, [
      _vm._v("결제/섭외를 취소하는 경우 아래의 규정을 따릅니다."),
      _c("br"),
      _c(
        "span",
        {
          staticClass: "ui-p-indent d-inline-block",
          staticStyle: { color: "#f0665d" }
        },
        [_vm._v("- 13~7일 전 취소 시 : 위약금 50%")]
      ),
      _c("br"),
      _c(
        "span",
        {
          staticClass: "ui-p-indent d-inline-block",
          staticStyle: { color: "#f0665d" }
        },
        [_vm._v("- 6일 전~당일 취소 시 : 취소 및 환불 불가")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ui-p-indent" }, [
      _vm._v("- 엔터테이너가 결제/섭외를 취소하거나"),
      _c("br"),
      _vm._v("약속을 이행하지 않는 경우 아래의 규정을 따릅니다.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "ui-p-indent", staticStyle: { color: "#ed3f33" } },
      [
        _vm._v("- 6일 전~당일 취소 시 : 취소 불가"),
        _c("br"),
        _vm._v("* 취소 및 불참 시 300% 배상 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }