
export const isAppliedArtist = (artistData = {}) => {
    return [
        () => artistData?.offerUid > 0,
        () => artistData?.proPrice > 0,
    ].every((test) => {
        if (test()) {
            return true
        } else {
            return false
        }
    })
}

export const isEarlybirdEventArtist = (artistData = {}, offerData = {}) => {
    const oneday = 60 * 60 * 24
    const now = Math.floor(Date.now() / 1000)
    const offerCreatedDate = parseInt(offerData?.createdDate, 10) || 0

    return [
        () => artistData?.saleType === 'earlybird',
        () => artistData?.salePer > 0,
        () => offerCreatedDate + oneday > now
    ].every((test) => {
        if (test()) {
            return true
        } else {
            return false
        }
    })
}

export const calcEarlybirdEventTimer = (artistData = {}, offerData = {}, cb) => {
    const pad = (num) => String(num).padStart(2, '0')
    const oneday = 60 * 60 * 24
    const now = Math.floor(Date.now() / 1000)
    const offerCreatedDate = parseInt(offerData?.createdDate, 10) || 0
    const remain = offerCreatedDate + oneday - now

    if (isEarlybirdEventArtist(artistData, offerData) && remain >= 0) {
        const sec = remain % 60
        const min = (remain - sec) / 60 % 60
        const hour = (remain - sec - min * 60) / 60 / 60
        const time = `${pad(hour)}:${pad(min)}:${pad(sec)}`
        cb(time)
        setTimeout(() => {
            calcEarlybirdEventTimer(artistData, offerData, cb)
        }, 1000)
    }
}

export const isCustomArtist = (artistData = {}, userData = {}) => {
    return [
        () => artistData?.artistidx > 0,
        () => userData?.useridx > 0,
        () => artistData?.artistidx != userData?.artistidx,
        () => artistData.priceType == 'fixed',
    ].every((test) => {
        if (test()) {
            return true
        } else {
            return false
        }
    })
}

export const isDiscountEventCustomArtist = (artistData = {}) => {
    return [
        () => artistData?.cusPriceStart > 0,
        () => artistData?.saleType === 'cusPrice_dc',
        () => artistData?.salePer > 0,
    ].every((test) => {
        if (test()) {
            return true
        } else {
            return false
        }
    })
}

export const isFluctuating = (artistData = {}) => {
    return artistData.priceType == 'variable';
}