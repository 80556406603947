<template>
  <!-- profile css 사용 -->
  <div class="container profile">
    <div class="payment-header">
      <div v-if="isSuccessfulPayment" style="height: 90px"/>

      <div class="image">
        <icon src="img/payment/payment_check.gif" width="8.8rem" height="8.8rem" :cover="false"/>
      </div>

      <div class="title">결제가 완료되었어요!</div>

      <template v-if="isSuccessfulPayment && isCustom">
        <div class="text">
          조금만 기다리시면<br>
          전담 매니저가 진행 도움을 위해 <span class="color-lavender">연락을 드려요.</span><br>
          (평일, 최대 12시간 내)<br>
          <br>
          <a class="text-underline ui-btn" @click="$gotoWeb('http://pf.kakao.com/_yzxhwC/chat')">빠른 도움이 필요하신가요?</a>
        </div>
      </template>
    </div>

    <div class="profile-info receipt-ui">
      <div v-if="$route.params.artistidx > 0">
        <!-- <h4>{{isCustom ? '결제 정보' : '예약 정보'}}</h4> -->
        <h4>예약 정보</h4>
        <div class="info-block">
            <div class="d-flex w-100">
              <span
                class="artist-image ui-bg-img"
                :style="{
                  backgroundImage: `url(${$lib.cdnUrl(artistData.teamImgUrl)})`,
                  backgroundColor: `#eee`
                }"
              />              
              <div class="product-detail">
                <div class="product-name ui-ellipsis" style="flex: 1; line-height: 2.8rem">{{artistData.teamName}}</div>
                <div
                  class="color-lavender"
                  :style="{
                    fontSize:'2rem',
                    fontWeight: 600,
                    lineHeight: '2.8rem',
                  }"
                  >{{$lib.addComma(isPartCancel ? cancellableAmt : finalPaymentPrice ? finalPaymentPrice : payAmt)}}원 (부가세 포함)</div>
              </div>
            </div>
        </div>
      </div>
      
      <div class="divider-dashed" style="margin: 2.4rem 0;"></div>

      <h4 v-if="offerData.nowState === 'wait' && offerData.payType === 'VBANK'">입금 정보</h4>
      <h4 v-else>결제 정보</h4>
      <!-- 입금대기중 && 가상계좌일때 -->
      <template v-if="offerData.nowState === 'wait' && offerData.payType === 'VBANK'">
        <div class="info-block">
          <ul>
            <li>계좌번호</li>
            <li class="float-right">{{offerData.payFnNm || '-'}} | {{pVacctNo || '-'}}</li>
          </ul>
        </div>
        <!--  -->
        <div class="info-block">
          <ul>
            <li>입금기한</li>
            <li class="float-right">{{moment(Number(pExpDt) * 1000).format('YYYY-MM-DD HH:mm:ss')}}</li>
          </ul>
        </div>
      </template>
      <!-- 카드결제일 때 -->
      <template v-else>
        <div class="info-block">
          <ul>
            <li>결제 일시</li>
            <li class="float-right">{{!isCustom
              ? payAuthDt(offerData.payAuthDt)
              : moment().format('YYYY-MM-DD HH:mm:ss')
            }}</li>
          </ul>
        </div>
        <!--  -->
        <div class="info-block" v-if="isClient && !isCustom">
          <ul>
            <li>결제 수단</li>
            <li class="float-right">{{offerData.payType === 'CARD' ? '신용카드' : '가상계좌'}}</li>
          </ul>
        </div>
      </template>
      <!--  -->
      <template v-if="isClient">
        <!-- <div class="info-block">
          <ul>
            <li>결제 금액</li> -->
            <!-- finalPaymentPrice 가 nan 이면 payAmt 로 표시 -->
            <!-- <li class="float-right">{{$lib.addComma(finalPaymentPrice ? finalPaymentPrice : payAmt)}}원</li>
          </ul>
        </div> -->
        <!--  -->
        <!-- <div class="info-block" v-if="!isCustom && priceFees != 0">
          <ul>
            <li>안전 결제 수수료 {{priceFeesPer > 0 ? `(${priceFeesPer}%)` : ''}}</li>
            <li class="float-right">{{$lib.addComma(priceFees)}}원</li>
          </ul>
          <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
        </div> -->
        <!--  -->
        <div class="info-block" v-if="!isCustom">
          <ul>
            <li class="color-lavender ui-weight-6">최종 결제 금액</li>
            <li
              class="color-lavender ui-weight-6 float-right"
              :style="{
                fontSize:'2rem',
                fontWeight: 600,
              }"
              >{{isPartCancel ? $lib.addComma(cancellableAmt) : $lib.addComma(payAmt)}}원</li>
          </ul>
        </div>
        <!--  -->
      </template>
      <!-- 전문가 -->
      <template v-else>
        <div class="info-block">
          <ul>
            <li>결제 금액</li>
            <li
              class="float-right"
              >{{isPartCancel ? $lib.addComma(cancellableAmt) : $lib.addComma(payAmt)}}원</li>
          </ul>
        </div>
        <!--  -->
        <!-- <div class="info-block">
          <ul>
            <li>지원 금액</li>
            <li class="float-right">{{$lib.addComma(proPrice)}}원</li>
          </ul>
          <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
        </div> -->
        <div class="info-block">
          <ul>
            <li>부가세</li>
            <li class="float-right">{{$lib.addComma(artistVat)}}원</li>
          </ul>
        </div>
        <!--  -->
        <div class="info-block">
          <ul>
            <li>수수료</li>
            <li class="float-right">{{$lib.addComma(fees)}}원</li>
          </ul>
        </div>
        <!--  -->
        <div class="info-block">
          <ul>
            <li
              class="color-lavender"
              :style="{
                fontSize:'2rem',
                fontWeight: 600,
              }"
              >예상 수익 금액</li>
            <li
              class="color-lavender float-right"
              :style="{
                fontSize:'2rem',
                fontWeight: 600,
              }"
              >{{$lib.addComma(incomePrice)}}원</li>
          </ul>
        </div>
      </template>

      <!-- <div class="ui-border-line ui-h-1 ui-mb-4" style="height: 10px" v-if="(isClient && $route.params.artistidx > 0) || isArtist"></div> -->

      <!-- <template v-if="isClient && $route.params.artistidx > 0">
        <h4>{{!isCustom ? '섭외 정보' : '결제 대상'}}</h4>
        <div class="ui-border-line ui-mt-1"></div>
        <div class="info-block">
          <ul>
            <li>
              <span
                class="artist-image ui-bg-img"
                :style="{
                  backgroundImage: `url(${$lib.cdnUrl(artistData.teamImgUrl)})`,
                }"
              />
              {{artistData.teamName}}
            </li> -->
            <!-- <li class="float-right" @click="() => gotoProfile()">
              <icon src="img/common/right-arrow-gray.png" width="2rem" height="3.4rem" :cover="false" />
            </li> -->
          <!-- </ul>
          <div class="ui-border-line"></div>
        </div>
      </template> -->

      <!-- <template v-if="isArtist && !isCustom">
        <h4>의뢰 정보</h4>
        <div class="info-block">
          <ul>
            <li>{{$lib.t_genre(offerData.genre)}} | {{offerData.eventType}}&nbsp;&nbsp;&nbsp;{{playDate(offerData.playDate)}}</li>
            <li class="float-right" @click="() => gotoOffer()">
              <icon src="img/common/right-arrow-gray.png" width="2rem" height="3.4rem" :cover="false" />
            </li>
          </ul>
          <div class="ui-border-line"></div>
        </div>
      </template> -->

    </div>

    <template v-if="!isCustom">
      <div class="profile-teamDesc">
        <template v-if="isClient">
          <div class="safe-infomation" :class="{open: safeInfomationOpen}">
            <h4 :style="{fontSize: '2.1rem', fontWeight: 500, margin: '1rem 0'}">헤이비글 안전결제 안내 <icon src="img/common/iconfinder-down.png" width="22px" height="2.4rem" :cover="false" @click="() => safeInfomationOpen = !safeInfomationOpen"/></h4>
            <p class="ui-p-indent-2">1. 헤이비글은 의뢰자와 엔터테이너간 No-Show 및<br>대금 미지급에 대한 문제가 발생하지 않도록<br>안전거래(에스크로) 방식을 도입하고 있습니다.</p>
            <p class="ui-p-indent-2">2. 결제 금액은 헤이비글이 안전하게 보관 후<br>등록된 행사일 3일 후 전문가에게 지급합니다.</p>
            <p class="ui-p-indent-2">3. 피해가 발생한 경우 2일 이내에 고객센터로<br>문제 제기 시 대금 지급을 보류합니다.</p>
          </div>
        </template>
        <template v-else>
          <h4 :style="{fontSize: '2.4rem', fontWeight: 500}">대금 지급 안내</h4>
          <p class="ui-p-indent-2">
            1. 용어 설명<br>
            - 부가세 : 거래 시 최종 소비자가 부담하는 세금<br>
            - 원천세 : 국세청에 납부하는 개인소득에 대한 세금<br>
            - 수수료 : 헤이비글 이용 수수료
          </p>
          <p class="ui-p-indent-2">2. 예상액이므로 실제 금액과 차이가 있을 수 있어요.</p>
          <p class="ui-p-indent-2">3. 개인의 경우 원천세 공제 후 지급됩니다.</p>
          <p class="ui-p-indent-2">4. 사업자는 세금계산서 발행이 필요합니다.</p>
          <p class="ui-p-indent-2">
            5. 정산일 안내<br>
            - 1일~15일 진행건 : 23일에 지급<br>
            - 16일~말일까지의 진행건 : 익월 9일에 지급
          </p>
          <p class="ui-p-indent-2">
            6. 단, 의뢰자가 피해로 인한 문제 제기 시<br>
            양자 간 협의 완료 전까지 대금 지급은 보류됩니다.
          </p>
        </template>
      </div>
      <div class="ui-border-line ui-h-1" style="height: 10px"></div>
    </template>

    <div class="profile-teamDesc">
      <template v-if="isClient">
        <h4 :style="{fontSize: '2.4rem', fontWeight: 500}">환불 및 취소 안내</h4>
        <template v-if="isCustom && cusFlag === 2">
          <p class="ui-p-indent-2">1. 환불 및 취소는 2주 전까지만 가능하며<br>시점에 따라 위약금이 발생합니다.
          <span class="ui-p-indent d-inline-block">행사 30일 전까지 : 100% 환불</span><br>
          <span class="ui-p-indent d-inline-block">행사 29일 ~ 14일 전 취소 시 : 50% 환불</span><br>
          <span class="ui-p-indent d-inline-block">행사 13일 ~ 당일 : 환불 불가</span></p>
          <p class="ui-p-indent-2">2. 환불 문의<br>
          <span class="ui-p-indent d-inline-block">고객센터 : 02-3144-1806, 카카오톡@헤이비글</span><br>
          <span class="ui-p-indent d-inline-block">상담시간 : 평일 10시~18시 (주말 및 공휴일 제외, 점심 13시~14시 제외)</span></p>
        </template>
        <template v-else-if="isCustom && cusFlag === 1">
          <p class="ui-p-indent-2">1. 환불 안내<br>
          <span class="ui-p-indent d-inline-block">체크인일자 3일전 취소 시 공제없이 100% 환불</span><br>
          <span class="ui-p-indent d-inline-block">체크인일자 2일전 취소 시 30%공제 후 환불</span><br>
          <span class="ui-p-indent d-inline-block">체크인일자 1일전 취소 시 50%공제 후 환불</span><br>
          <span class="ui-p-indent d-inline-block">체크인일자 당일/No-Show 취소 및 환불불가</span><br>
          <span class="ui-p-indent d-inline-block">주말 및 공휴일 취소 시 익영업일에 취소한 것으로 인정</span></p>
          <p class="ui-p-indent-2">2. 예약 및 취소 문의<br>
          <span class="ui-p-indent d-inline-block">고객센터 : 02-3144-1806, 카카오톡@헤이비글</span><br>
          <span class="ui-p-indent d-inline-block">상담시간 : 평일 10시~18시 (주말 및 공휴일 제외, 점심 13시~14시 제외)</span></p>
        </template>
        <template v-else>
          <p class="ui-p-indent-1">결제/섭외를 취소하는 경우 아래의 규정을 따릅니다.<br>
          <span class="ui-p-indent d-inline-block" style="color: #f0665d;">- 13~7일 전 취소 시 : 위약금 50%</span><br>
          <span class="ui-p-indent d-inline-block" style="color: #f0665d;">- 6일 전~당일 취소 시 : 취소 및 환불 불가</span></p>
          <br>
          <p class="ui-p-indent-1">엔터테이너가 취소 시 아래의 규정을 따릅니다.<br>
          <span class="ui-p-indent d-inline-block">- 13~7일 전 취소 시 : 결제금액의 50%를 배상</span><br>
          <span class="ui-p-indent d-inline-block">- 6일 전~당일 취소 시 : 취소 불가, 300%를 배상</span><br>
          <span class="ui-p-indent color-lavender text-underline" @click="$router.push('/etc/refund-policy')">자세히 알아보기</span></p>
        </template>
      </template>
      <template v-else>
        <h4 :style="{fontSize: '2.4rem', fontWeight: 500}">전문가의 취소 및 위약금 규정</h4>
        <p class="ui-p-indent">- 엔터테이너가 결제/섭외를 취소하거나<br>약속을 이행하지 않는 경우 아래의 규정을 따릅니다.</p>
        <p class="ui-p-indent" style="color: #ed3f33">- 13~7일 전 취소 시 : 결제금액의 50% 배상</p>
        <p class="ui-p-indent" style="color: #ed3f33">- 6일 전~당일 취소 시 : 취소 불가<br>* 취소 및 불참 시 300% 배상 </p>
        <p class="ui-p-indent">- 자연재해, 사고, 입원 등의 불가피한 사유인 경우는<br>의뢰인과의 협의를 통해 결정합니다.<br><span class="color-lavender text-underline" @click="$router.push('/etc/refund-policy')">자세히 알아보기</span></p>
      </template>
    </div>
    <!-- <div class="ui-border-line ui-h-1" style="height: 10px"></div> -->

    <template v-if="isClient">
      <template v-if="isSuccessfulPayment">
        <template v-if="artistData.categoryType != 'profile'">
          <footer-box
            :single="true"
            submit-text="닫기"
            :submit-cb="() => {
              if (isCustom) {
                // 메인으로 이동
                $router.push('/home')
              } else {
                // 결제 내역으로 이동
                $router.push('/client/my-offer?nav=2')
              }
            }"
          />
        </template>
        <template v-else>
          <footer-box
            rate37
            :single="false"
            btn-text="닫기"
            :btn-cb="() => {
              if (isCustom) {
                // 메인으로 이동
                $router.push('/home')
              } else {
                // 결제 내역으로 이동
                $router.push('/client/my-offer?nav=2')
              }
            }"
            submit-text="전문가와 채팅하기"
            :submit-cb="() => createMsgRoom()"
          />
        </template>
      </template>
      <template v-else-if="!isCustom">
        <footer-box
          v-if="offerData.payType === 'VBANK' ? offerData.nowState !== 'wait' : offerData.nowState !== 'cancelRequest' && canCancel"
          :single="true"
          :submit-text="'결제 취소하기'"
          :submit-cb="() => paymentAction('artistCancel')"
        />
      </template>
    </template>

    <page-modal
      ref="pageModal"
      :use-navi="false"
      >
      <div
        slot="body"
        class="page-modal-body"
        :style="{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: 0,
        }"
        >
        <div
          :style="{
            margin: 'auto',
          }"
          >
          <img
            slot="body"
            :src="$lib.imgUrl('img/payment/checker.png')"
            alt="image"
            width="128px"
            height="128px"
            :style="{
              margin: '0 auto 30px',
              display: 'block',
            }"
          />
          <div
            class="ui-weight-5"
            :style="{
              fontSize: '28px',
              color: '#000',
              textAlign: 'center',
              marginBottom: '20px',
            }"
          >
            예약 완료!<br>
            조금만 기다려주세요.
          </div>
          <div
            class="ui-weight-5"
            :style="{
              fontSize: '20px',
              color: '#5a5a5a',
              textAlign: 'center',
            }"
          >
            헤이비글의 전담 매니저가 연락을 드리고<br>
            진행을 도와드릴거예요!<br>
            (영업일 기준, 최대 12시간 내)<br>
            <br>
            <a class="text-underline ui-btn" @click="$gotoWeb('http://pf.kakao.com/_yzxhwC/chat')">빠른 도움이 필요하신가요?</a>
          </div>
        </div>
      </div>
      <div
        v-if="offerData && /(결혼식|주례없는 결혼식)/.test(offerData.eventType)"
        class="ui-weight-5 d-flex align-items-center justify-content-center ui-btn"
        @click="$gotoWeb('https://blog.naver.com/wimz0816/222641646144')"
        :style="{
          fontSize: '22px',
          color: '#000',
          height: '85px',
          position: 'absolute',
          bottom: '100px',
          left: 0,
          right: 0,
          borderTop: '1px solid #ececec',
          width: '100%',
        }"
      >리뷰 쓰고 최대 50,000원 상품권 받기  ></div>
      <FooterBox submit-text="확인" :submit-cb="() => $refs.pageModal.hide()" />
    </page-modal>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import PageModal from '@/components/common/PageModal'
import { isDiscountEventCustomArtist, isEarlybirdEventArtist } from '@/lib/artist'

export default {
  name: 'PaymentResult',
  components: {
    Icon,
    FooterBox,
    PageModal,
  },
  data() {
    return {
      offerData: {},
      artistData: {},
      safeInfomationOpen: false,
      artistOption: [],
      extProd: [],
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    isArtist() {
      return this.userData.category === 'artist'
    },
    offerUid() {
      return this.$route.params.offerUid
    },
    isCustom() {
      return this.offerUid === 'custom'
    },
    cusFlag() {
      return this.$config.constant.cusFlag.indexOf(this.artistData.cusFlag)
    },
    playDate() {
      return (playDate) => {
        let date = moment(Number(playDate) * 1000)
        // let noon = '오전'
        // if (date.format('H') >= 12) noon = '오후'
        // return date.format(`YYYY.MM.DD / ${noon} hh시 mm분`)
        return date.format(`YYYY.MM.DD`)
      }
    },
    proPrice() {
      return Math.floor(Number(this.artistData?.proPrice))
    },
    confirmFeesPer() {
      return this.offerData.userReferrer
        ? this.$config.constant.fees.hbFeePerForUserReferrer
        : this.$config.constant.fees.hbFeePer
    },
    priceFeesPer() {
      if (this.offerData.payType === 'CARD') {
        return this.priceFees / Number(this.proPrice)
      }
      return 0
    },
    priceFees() {
      if (this.isClient && this.offerData.payType === 'VBANK') {
        return 1300
      }
      return (this.payAmt - Number(this.proPrice))
    },
    paymentPrice() {
      if (this.isCustom) {
        return Number(this.artistData.cusPriceStart)
      }
      return Number(this.proPrice)
    },
    afterDiscountPaymentPrice() {
      return this.$lib.floor(this.paymentPrice - Math.floor(this.paymentPrice * this.artistSalePer / 100), 2)
    },
    afterFeesPaymentPrice() {
      if (this.isCustom) {
        return this.afterDiscountPaymentPrice
      }
      return this.afterDiscountPaymentPrice + this.priceFees
    },
    isPartCancel() {
      if (['partCancel'].indexOf(this.offerData?.nowState) !== -1) return true
      return false
    },
    finalPaymentPrice() {
      let price = 0;
      price = this.afterFeesPaymentPrice - this.couponDiscountPrice
      if(this.artistOption && this.artistOption.length > 0) {
        // 옵션 있을 때 정한 옵션 값들 더하기
        price = (this.artistOption.reduce((p,c,i)=>p+((this.afterFeesPaymentPrice + c.price) * c.count),0));
      } else if (this.isCustom) {
        // 옵션 없을 때 상품 가격
        price = this.afterFeesPaymentPrice 
      }

      if(this.extProd) {
        price = this.extProd.reduce((p,c,i)=>p+(c.price * c.count), price);
      }

      // 별도 작업비 더하기
      if(this.artistData.addWorkPrice) {
        price = price + Number(this.artistData.addWorkPrice);
      }
      if(this.artistData.includeVatFlag == 'n' && this.artistData.priceType) {
        price = price + price*0.1;
      }
      return price;
    },
    payAuthDt() {
      return payAuthDt => {
        if (!payAuthDt) return payAuthDt
        const YYYY = payAuthDt.substr(0, 4)
        const MM = payAuthDt.substr(4, 2)
        const DD = payAuthDt.substr(6, 2)
        const HH = payAuthDt.substr(8, 2)
        const mm = payAuthDt.substr(10, 2)
        const ss = payAuthDt.substr(12, 2)
        return `${YYYY}-${MM}-${DD} ${HH}:${mm}:${ss}`
      }
    },
    canCancel() {
      return this.$config.constant.paymentRelatedDates.canCancel(this.remainDays)
    },
    submitText() {
      switch (this.offerData.nowState) {
        case 'cancel':
          return '결제 취소'
        case 'pay':
          return '지급 완료'
        default:
          return '대금 지급하기'
      }
    },
    submitDisabled() {
      // ing:모집중
      // wait:입금대기
      // end:섭외완료
      // cancelRequest:결제취소요청
      // cancel:결제취소
      // payRequest:지급요청
      // pay:지급완료
      // selfEnd:본인직접마감
      return ['end'].indexOf(this.offerData.nowState) === -1
    },
    remainDays() {
      const now = moment(moment(new Date()).format('YYYY-MM-DD'))
      const end = moment(moment(Number(this.offerData.playDate) * 1000).format('YYYY-MM-DD'))
      return end.diff(now, 'days')
    },
    // 계좌 번호
    pVacctNo() {
      if (!this.offerData || !this.offerData.payRmesg1 || typeof this.offerData.payRmesg1 !== 'string') return '-'
      let payRmesg1 = this.offerData.payRmesg1.split('|')
      if (payRmesg1.length < 2) return '-'
      return payRmesg1[0]
    },
    // 입금기한
    pExpDt() {
      if (!this.offerData || !this.offerData.payRmesg1 || typeof this.offerData.payRmesg1 !== 'string') return ''
      let payRmesg1 = this.offerData.payRmesg1.split('|')
      if (payRmesg1.length < 2) return ''
      return payRmesg1[1]
    },
    /**
     * artist 전용
     */
    // 부가세
    artistVat() {
      return Math.floor(this.payAmt / 11)
    },
    // 헤이비글 수수료
    fees() {
      const confirmFeesPer = this.confirmFeesPer / 100
      return Math.floor(this.artistVat * 10 * confirmFeesPer)
    },
    // 예상 수익 금액
    incomePrice() {
      return this.payAmt - this.artistVat - this.fees
    },
    payAmt() {
      return Number(this.offerData?.payAmt)
    },
    cancellableAmt() {
      return Number(this.offerData?.cancellableAmt)
    },
    isSuccessfulPayment: {
      cache: false,
      get() {
        return localStorage.getItem('successfulPayment') ? true : false
      },
    },
    artistSalePer() {
      const salePer = Number(this.artistData?.salePer) || 0
      const useSalePer = [
        () => this.isEarlybirdEventArtist,
        () => this.isCustom && this.isDiscountEventCustomArtist
      ].some((test) => test())

      if (useSalePer) {
        return salePer
      }

      return 0
    },
    isEarlybirdEventArtist() {
      return isEarlybirdEventArtist(this.artistData, this.offerData)
    },
    isDiscountEventCustomArtist() {
      return isDiscountEventCustomArtist(this.artistData)
    },
  },
  created() {
    const { offerUid, artistidx } = this.$route.params
    let promiseArr = []
    this.artistOption = localStorage.getItem(`artistOption:${artistidx}`);
    this.extProd = localStorage.getItem(`extProd:${artistidx}`);
   
    if (this.artistOption || this.extProd) {
      this.artistOption = JSON.parse(this.artistOption);
      this.extProd = JSON.parse(this.extProd);
      
      // artistOption 은 label, price, count 가 있는데 label(price)(count) 형식으로 문자열을 만들어서 보내줘야함
      let artistOptionString = this.artistOption.map((item) => {
        return `${item.label}(${item.price})(${item.count})`;
      }).join('\n');

      let extProdString = this.extProd.map((item) => {
        return `${item.label}(${item.price})(${item.count})`;
      }).join('\n');

      let payUid = this.$route.query.payUid;
      if (payUid !== undefined && payUid != 0) {
        const req = {
          method: 'post',
          url: `/offerPayResult/option/${payUid}`,
          data: {
            optionString: artistOptionString,
            extProdString: extProdString,
          }
        }

        this.$http(req)
          .then(({ data }) => {
            log(...Object.values(req), data)
            localStorage.removeItem(`artistOption:${artistidx}`)
            localStorage.removeItem(`extProd:${artistidx}`)
          })
          .catch(log.error)
      }
    }

    let p_msg = this.$route.query.p_msg
    if (p_msg !== undefined && p_msg) {
      localStorage.removeItem('TryPayment')
      this.$router.replace(`/payment/${offerUid}/${artistidx}`, () => {
        setTimeout(() => {
          this.$store.commit('setModal', {
            show: true,
            body: p_msg || '결제가 취소되었습니다.',
            useCancel: false,
          })
        }, 200)
      })
    } else if (localStorage.getItem('TryPayment')) {
      localStorage.removeItem('TryPayment')
      localStorage.setItem('successfulPayment', Date.now().toString())
    }

    if (offerUid && !this.isCustom) {
      promiseArr.push(this.getOffer())
    }

    if (this.$route.params.artistidx == 0) {
      this.$toast('전문가 정보를 불러오는데 실패하였습니다.')
    } else {
      let artistData = sessionStorage.getItem(`artistData:${this.$route.params.artistidx}:${this.offerUid}`)
      if (artistData) {
        this.artistData = JSON.parse(artistData)
      } else {
        promiseArr.push(this.getProfile())
      }
    }

    Promise.all(promiseArr).then(() => {
      if (this.offerData.nowState === 'ing') {
        this.$router.push('/home')
      }
    })
  },
  mounted() {
    this.pageInit()
  },
  destroyed() {
    this.pageReset()
  },
  methods: {
    pageInit() {
      if (this.isClient) {
        FB.AppEvents.logEvent('click', {desc:`일반회원 > 결제결과 페이지`})
      }

      if (this.isSuccessfulPayment) {
        // 이 기능은 더 이상 안씀
        // this.$refs.pageModal.show()

        this.$store.commit('setGnbHeight', 0)
        this.$store.commit('setGnbBg', 'transparent')
        this.$store.commit('setGnbLeftBtn', 'none')
        this.$store.commit('setGnbLeftBtnCb', () => {})
      }
    },
    pageReset() {
      this.$store.commit('setGnbHeight')
      this.$store.commit('setGnbBg', null)
      this.$store.commit('setGnbLeftBtn', null)
      this.$store.commit('setGnbLeftBtnCb', null)
    },
    getOffer() {
      if (this.isCustom) return Promise.resolve()

      const req = {
        method: 'get',
        url: `/client/offer/${this.offerUid}`,
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.offerData = data.response.offerData || {}

            if (this.isClient && this.offerData.useridx != this.userData.useridx) {
              this.$toast('잘못된 접근입니다').back()
            }
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    getProfile() {
      const { offerUid, artistidx } = this.$route.params
      const req = {
        method: 'get',
        url: `/artist/profile/${artistidx}`,
      }

      if (offerUid && !this.isCustom) {
        req.method = 'post'
        req.data = {
          offerUid,
        }
        if (this.isClient) req.data.offerRead = 'y'
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.artistData = data.response.artistData || {}
            // sessionStorage.setItem(`artistData:${this.artistData.artistidx}:${this.offerUid}`, JSON.stringify(this.artistData))
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    gotoProfile() {
      const { offerUid, artistidx } = this.$route.params
      const historyStack = this.$store.state.history.stack
      const profileUrl = !this.isCustom
        ? `/client/my-offer/${offerUid}/${artistidx}`
        : `/artist/profile/${artistidx}`

      if (historyStack.indexOf(profileUrl) === -1) {
        this.$router.push(profileUrl)
      } else {
        this.$router.back()
      }
    },
    gotoOffer() {
      this.$router.push(`/client/my-offer/${this.offerUid}`)
    },
    /**
     * 대금지급하기 버튼 클릭
     * @param {'payRequest' | 'artistCancel'} action
     * @param {boolean} confirm
     */
    paymentAction(action, confirm = false) {
      if (!action) return

      if (action === 'payRequest' && !confirm) {
        this.$store.commit('setModal', {
          show: true,
          title: '일은 잘 진행되셨나요?',
          body: '지금 바로\n대금을 지급할까요?',
          confirm: '네 지급합니다.',
          confirmCb: modal => {
            this.paymentAction('payRequest', true)
            modal.hide()
          },
          useCancel: true,
        })
        return
      }

      if (action === 'artistCancel') {
        this.$router.push(`/payment/${this.offerUid}/${this.$route.params.artistidx}/cancel`)
        return
      }

      const req = {
        method: 'put',
        url: `/client/offer/${this.offerUid}/state/${action}`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let msg = ''
            switch (action) {
              case 'payRequest':
                msg = '지급이 완료되었습니다.'
                break
              case 'artistCancel':
                msg = '취소가 완료되었습니다.'
                break
            }

            this.$historyStack.mulitPush('/home', [
              '/client/my-offer',
              `/client/my-offer/${this.$route.params.offerUid}/${this.$route.params.artistidx}`,
            ])
            this.$nextTick().then(() => {
              this.$toast(msg)
            })
          } else {
            let errMsg = {
              '-2': '의뢰 정보가 없습니다.',
              '-3': '취소할 수 없는 상태입니다.',
              '-4': '취소 중 오류가 발생하였습니다.',
            }
            this.$alert(errMsg[resultData.code] || resultData)
          }
        })
        .catch(log.error)
    },
    createMsgRoom() {
      // if ([this.offerData?.platform, this.offerData?.userPlatform].some(val => /^web_(PC|M)$/.test(val))) {
      //   this.$store.commit('setModal', {
      //     show: true,
      //     body: `
      //       <span style="font-size: 24px;font-weight: 600;color: #000;">채팅을 사용하지 않는<br>회원입니다.</span>

      //       헤이비글 담당자에게
      //       카카오톡 연결을 요청하세요.`,
      //     textAlign: 'text-center',
      //     confirm: '확인',
      //     confirmCb: modal => {
      //       modal.hide()
      //     },
      //     noBorder: true,
      //     useCancel: false,
      //   })
      //   return
      // }

      const req = {
        method: 'post',
        url: `/chat/${this.offerData.offerUid}`,
        data: {
          useridx: this.offerData.useridx,
          targetUseridx: this.artistData.artistidx,
          targetTeamName: this.artistData.teamName,
        }
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$router.push(`/message/${this.offerData.offerUid}/${this.artistData.artistidx}`)
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.divider-dashed {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e5e5e5;
}

.payment-header {
  // background-color: $color-deepLavender;
  background-color: #fff;
  color: #000;
  text-align: center;
  margin: 0 -3rem;
  overflow: hidden;
  .title {
    margin: 2rem 0 5.8rem;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 3.9rem;
  }
  .text {
    margin: 3rem 0;
    font-size: 2rem;
    line-height: 1.5;
    color: #5a5a5a;
  }
  .image {
    div {
      margin: 4rem auto 3.8rem;
    }
    img {
        margin: 4rem auto 3.8rem; 
        width: 8.8rem;
        height: 8.8rem;
    }
  }
}
.artist-image {
  display: block;
  width: 6.8rem;
  height: 6.8rem;
  border-radius: 0.94rem;
  margin-right: 1.8rem;
}
#page h4 {
  font-size: 2.4rem;
  color: #000;
  line-height: 3.4rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
}
#page > .container.profile {
  .profile-info .info-block ul li {
    //height: 6rem;
    padding: 0;
    width: auto;
    color: #555;
    font-size: 2rem;
    line-height: 2.8rem;
    &:first-child {
      width: auto;
      color: #6f7785;
    }
    &:nth-child(2) {
      color: #000;
      font-weight: 600;
    }
  }

  .safe-infomation {
    h4 {
      .icon {
        float: right;
        opacity: 0.2;
        cursor: pointer;
      }
    }
    p {
      display: none;
    }
    &.open {
      .icon {
        transform: rotate(180deg);
      }
      p {
        display: block;
      }
    }
  }

  .profile-info {
    &.receipt-ui {
      position: relative;
      margin-bottom: 2.8rem;
      padding: 3.4rem 2.4rem;
      z-index: 1;
      &::after {
        position: absolute;
        top:-6px;
        left: -8px;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        background: url('../../../public/img/payment/receipt-bg.png') no-repeat 0 0/100% 100%;
        content:'';
        z-index: -1;
      }
    }
    .info-block {
      & + .info-block {
        margin-top: 1.8rem;
      }
      .product-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.2rem 0;
        .product-name {
          color: #000;
          font-size: 2rem;
          font-weight: 600;
          line-height: 2.8rem;
        }
      }
    }
  }
}
</style>
